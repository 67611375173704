.navbar {
    /* background: #373737; */
    background: linear-gradient(0deg, #d9d9d9 0%, #efefef 100%);
    color: black;
    padding: 0;
    width: 300px;
    height: 100%;
    position: fixed;
    left: 0px;
    overflow-x: visible;
}

.navbar--title {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: center;
}

.title--link {
    font-family: "Segoe UI";
    font-size: 25px;
    font-weight: 700;
    text-decoration: none;
    color: black;
}

.title--link:visited {
    text-decoration: none;
    color: black;
}

.navbar>hr {
    width: 90%;
    border: 2px solid black;
    background-color: black;
}

.navbar--img {
    margin-left: 15px;
    max-width: 80px;
}

.navbar--list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

/* Dropdown menu styling */
.primary-dropdown {
    display: block;
    position: relative;
}

.primary-dropdown--title {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    justify-content: space-between;
    cursor: default;
}

.primary-dropdown--title:hover {
    background-color: #cbcaca;
}

.primary-dropdown--menu {
    display: none;
    -webkit-transform: translate(300px, -54px);
            transform: translate(300px, -54px);
    position: absolute;
    background-color: #e7e7e7;
    width: 250px;
}

.primary-dropdown--menu.effects {
    -webkit-transform: translate(300px, -108px);
            transform: translate(300px, -108px);
}

.primary-dropdown--menu.models {
    -webkit-transform: translate(300px, -162px);
            transform: translate(300px, -162px);
}

.primary-dropdown--title:hover~.primary-dropdown--menu {
    display: block;
}

.primary-dropdown--menu:hover {
    display: block;
}

.secondary-dropdown {
    display: block;
    position: relative;
}

.secondary-dropdown--title {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    justify-content: space-between;
    cursor: default;
}

.secondary-dropdown--title:hover {
    background-color: #cbcaca;
}

.secondary-dropdown--menu {
    display: none;
    -webkit-transform: translate(250px, -54px);
            transform: translate(250px, -54px);
    position: fixed;
    background-color: #e7e7e7;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.secondary-dropdown--menu.concurrent {
    -webkit-transform: translate(250px, -107px);
            transform: translate(250px, -107px);
}

.secondary-dropdown--menu.sequential {
    -webkit-transform: translate(250px, -161px);
            transform: translate(250px, -161px);
}

.secondary-dropdown--menu.segmental {
    -webkit-transform: translate(250px, -214px);
            transform: translate(250px, -214px);
}

.secondary-dropdown--title:hover~.secondary-dropdown--menu {
    display: block;
}

.secondary-dropdown--menu:hover {
    display: block;
}

.secondary-dropdown--menu>span {
    display: flex;
    padding: 4px;
    border-top: thin solid black;
    border-bottom: thin solid black;
    width: 250px;
    position: relative;
    justify-content: center;
    font-weight: bold;
    cursor: default;
}

/* Dropdown menu triangle styling*/

.primary-dropdown:hover .primary-dropdown--triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid black;
    margin-right: 20px;
}

.primary-dropdown:not(:hover) .primary-dropdown--triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid black;
    margin-right: 20px;
}

.secondary-dropdown:hover .secondary-dropdown--triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid black;
    margin-right: 20px;
}

.secondary-dropdown:not(:hover) .secondary-dropdown--triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid black;
    margin-right: 20px;
}

/* End dropdown menu triangle styling */

/* End dropdown menu styling*/

.navbar--list li a {
    display: block;
    text-decoration: none;
    padding: 16px 16px;
    color: black;
}

.navbar--list li a:hover {
    background-color: #cbcaca;
    color: black;
}

.navbar--list li a:visited {
    background-color: #cbcaca;
    color: black;
}

.navbar--list button {
    background-color: transparent;
    border: none;
    padding: 16px 16px;
    width: 300px;
    cursor: pointer;
    text-align: left;
}

.navbar--list button:hover {
    background-color: #cbcaca;
}