* {
    box-sizing: border-box;
}

.feedback--container {
    position: absolute;
    right: 0px;
    width: calc(100% - 300px);
    background-color: whitesmoke;
    min-height: 100vh;
}

.feedback--header {
    display: flex;

    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../images/brass-feedback.jpg);
    background-position: 0% 33%;
    background-repeat: no-repeat;
    background-size: cover;
}

.feedback--header>h1 {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

.feedback--content {
    padding: 30px 30px;

}

.feedback--form>label {
    font-weight: 500;
    line-height: 2em;
}

.feedback--input {
    width: 60%;
    padding: 8px 12px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    /* Border */
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    border-color: lightgray;
}

.feedback--alert {
    width: 60%;
    margin-top: 1.3em;
}

.feedback--submit {
    background-color: rgb(71, 71, 247);
    color: white;
    font-weight: 550;
    padding: 8px 16px;
    cursor: pointer;

    border: none;
    border-radius: 5px;
}

.feedback--submit:active {
    background-color: rgb(34, 34, 190);
}