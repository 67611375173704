* {
    box-sizing: border-box;
}

.qb--content {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.qb--header {
    display: flex;

    width: 100%;
    height: 8.3em;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 4em;

    /* Background image */
    background-image:
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), transparent),
        url(../../images/organ-stops.jpg);
    background-position: 0% 30%;
    background-repeat: no-repeat;
    background-size: cover;
}

.qb--header-h1 {
    color: white;
    font-weight: bold;
}

.qb--main {
    padding: 1em 1em 1em 1em;
    background-color: whitesmoke;
}

.qb--sh {
    grid-area: sh;
}

.qb--main-savequery input {
    grid-area: saveQuery;
    position: relative;
    top: 50px;
    left: 30px;
    background-color: #13B215;
    color: white;
    font-weight: 550;
    padding: 8px 5px;
    width: 200px;
    cursor: pointer;
    /* border */
    border-radius: 5px;
    border-color: #13B215;
    border-style: solid;
}

.qb--main-savequery {
    margin-left: 2.3em;
}

.qb--group {
    grid-area: group;
}

.qb--submit-reset {
    display: none;
    grid-area: submit-reset;
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;

    /* Positioning */
    position: relative;
    right: 36px;
    padding-left: 62px;
    top: -52px;
}

.qb--submit-reset>button {
    font-size: 14px;
    padding: 5px 8px;
    cursor: pointer;
    /* Border */
    border-radius: 5px;
}

.qb--submit-button {
    background-color: #13B215;
    color: white;
    border: none;
}

.qb--submit-button:active {
    background-color: #109212;
}

.qb--reset-button {
    background-color: whitesmoke;
    color: black;

    border-color: lightgrey;
    border-style: solid;
    border-width: thin;
}

.qb--reset-button:active {
    background-color: lightgrey;
}

.qb--results {
    grid-area: results;
}

.qb--tutorial {
    position: relative;
    top: 2em;
    left: 2em;
    padding: 1em 1em;
    grid-area: tutorial;

    border: lightgrey;
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
    width: 200px;
    height: 120px;
}