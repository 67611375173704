* {
    box-sizing: border-box;
}

@media all and (max-width: 24em) {
    .hp--container {
        left: 30%;
    }
}

/* Title block */

.reg-done--title {
    font-family: "Roboto";
    color: darkslategray;
    display: flex;
    align-items: center;
    margin-left: 35%;
}

.title--2 {
    font-size: smaller;
}

.title--3 {
    color: dimgrey;
    font-size: smaller;
}

img {
    object-fit: contain;
}

.reg--container {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);

    background: whitesmoke;
    overflow-y: scroll;
    padding: 2em 0em 5em 0em;
}

.reg--form {
    display: flex;
    flex-direction: column;
    width: 24em;
    margin: auto;
}

.reg--title {
    display: flex;
    align-content: center;

}

.reg--form>form>span>label {
    font-weight: 500;
    line-height: 1em;
}

.reg--input {
    width: 26em;
    margin-top: 0.3em;
    padding: 8px 12px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    /* Border */
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    border-color: lightgray;
}

.reg--err {
    color: rgb(255, 0, 0);
    font-weight: bold;
    font-size: 0.8em;
}

.reg--submit {
    background-color: rgb(71, 71, 247);
    color: white;
    font-weight: 550;
    padding: 8px 16px;
    cursor: pointer;
    width: 10em;
    position: relative;
    left: 8.5em;

    border: none;
    border-radius: 5px;
}

.reg--submit:active {
    background-color: rgb(34, 34, 190);
}

.reg--footer {
    position: relative;
    left: 3em;
}

.reg--footer>a {
    color: blue;
    text-decoration: none;
}

.reg--footer>a:hover {
    text-decoration: underline;
    color: rgb(30, 30, 165);
}