* {
    box-sizing: border-box;
}

.login--container {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.login--content {
    position: relative;
    top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
}

@media all and (max-width: 980px) {
    .login--content {
        left: 30%;
    }
}

.login--title {
    font-family: "Roboto";
    color: darkslategray;
    display: flex;
    align-items: center;

}

.title--1 {
    font-family: "Roboto";
    font-size: 1.5em;
    color: darkslategray;
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    line-height: 1.2em;
}

.title--2 {
    font-size: 1.2em;
    color: darkslategray;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    line-height: 0;
}

.title--3 {
    color: dimgrey;
    font-size: 1em;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    line-height: 0;
}

img {
    object-fit: contain;
}

.login--login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0;
}

.login--form-element>label {
    position: relative;
    bottom: 3px;
    font-weight: 600;
}

.login--form-element>input {
    width: 308px;
    padding: 8px;
    border-radius: 5px;
    border-width: thin;
    border-color: lightgray;
    border-style: solid;
}

.login--err {
    color: rgb(255, 0, 0);
    font-weight: bold;
    font-size: 0.8em;
    align-self: flex-start;
}

/* Login button */

.login--submit {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 16px;
    color: white;
    background: linear-gradient(to bottom, #3261D8, #1D54DF);
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-right: auto;
    margin-left: auto;
    margin-top: 8px;
}

.login--submit:active {
    background: linear-gradient(to bottom, #284dac, #153ea5);
}

/* Login extras */

.login--utilities {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
}

.login--utilities a {
    text-decoration: none;
    color: teal;
}

.login--utilities a:visited {
    text-decoration: none;
    color: teal;
}

.login--utilities a:hover {
    text-decoration: underline;
    color: rgb(1, 92, 92);
}