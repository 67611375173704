.item-container {
    display: block;
    justify-content: center;
    gap: 2em;
}
.card {
    text-align: center;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    padding: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* grid-area: 1 / 1 / 2 / 2; */
}
.card:hover {
    background-color: hsl(0, 0%, 90%);
}
.search--results--title {
    grid-column: 1 / -1;
    margin-top: 1em;
    margin-left: 0.3em;
    margin-bottom: 0.3em;
    text-align: left;
    font-size: 24px;
}
.search--results--content {
    -webkit-columns: 3 auto;
            columns: 3 auto;
}

.search--results--column {
    padding: 10px;
    margin-right: 8px;
    text-align: left;
}
p {
    text-align: left;
}

.evolution--button {
    color: black;
    margin-left: 5px;
    text-decoration: underline;
}

.evolution--table{
    color: black;
    margin-left: 1em;
    width: 95%;
    table-layout: fixed;
    border-collapse: collapse;
}

.evolution--table--th {
    border-bottom: 0.000001em solid rgba(0, 0, 0, 0.221);
    padding: 5px 0px;

}