.ps--container {
    position: absolute;
    right: 0px;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 300px);
    row-gap: 20px;
}