* {
    box-sizing: border-box;
}

.movements--content {
    position: absolute;
    right: 0em;
    height: 100%;
    width: calc(100% - 300px);
}

.movements--header {
    display: flex;
    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../../images/sheet-music.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.movements--header>h1 {
    color: white;
    font-weight: bold;
}

.movements--main {
    padding: 2em 1em 0em 2em;
}

.movements--title {
    padding: 0em 0em 1em 0em;
}

.movements--bottom {
    padding: 1em 0em;
}
