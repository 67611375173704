* {
    box-sizing: border-box;
}

.parser--container {
    position: absolute;
    right: 0px;
    height: 100vh;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.parser--header {
    display: flex;

    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../images/network-parser.jpg);
    background-position: 0% 33%;
    background-repeat: no-repeat;
    background-size: cover;
}

.parser--header>h1 {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

.parser--content {
    padding: 30px 50px;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    margin: auto;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: white;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }
  
  .upload-button:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

.Mui-selected {
    color: #4f68a3 !important;
    background-color: #dce4ee !important;
    font-style: normal !important;
    border-radius: 2px;
}