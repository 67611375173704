* {
    box-sizing: border-box;
}

.sh--container {
    width: 200px;
    position: relative;
    left: 30px;
    top: 26px;
    /* Border */
    border-style: solid;
    border-width: thin;
    border-color: lightgrey;
    border-radius: 5px;
}

.sh--title {
    background: linear-gradient(ghostwhite, #e4e4e4);
    padding: 8px;
    letter-spacing: -0.2px;
    font-weight: 550;
}

.sh--form {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 16px;
}

.sh--form>select {
    padding: 5px;

    border-color: lightgrey;
    border-radius: 3px;
}

.sh--form>input {
    background-color: whitesmoke;
    color: black;
    padding: 5px 8px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 178px;
    cursor: pointer;

    border-color: lightgray;
    border-radius: 3px;
    border-style: solid;
    border-width: thin;
}

.sh--form>input:active {
    background-color: lightgray;
}