.tutorial--container {
    position: relative;
}

.tutorial--title {
    font-size: 18px;
    padding-top: 0.4em;
    line-height: 0.4em;
}

.tutorial--links {
    line-height: 1.6;
    font-size: 15px;
}

.tutorial--links>a {
    text-decoration: none;
    color: grey;
}

.tutorial--links>a:hover {
    text-decoration: underline;
}

/* .tutorial--buttons {
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.tutorial--buttons>button {
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer; */
/* Border */
/* border-radius: 5px;
    border: none;
}

.tutorial--tutorial-button {
    background-color: rgb(31, 153, 230);
    color: white;
}

.tutorial--example-button {
    background-color: rgb(212, 141, 10);
    color: white;
} */