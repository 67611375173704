* {
    box-sizing: border-box;
}

.tutpage--container {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.tutpage--header {
    display: flex;
    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), transparent),
        url(../../../images/organ-stops.jpg);
    background-position: 0% 60%;
    background-repeat: no-repeat;
    background-size: cover;
}

.tutpage--header>h1 {
    color: white;
    font-weight: bold;
}

.tutpage--main {
    padding: 2em 0.5em 0.5em 2em;
    background-color: whitesmoke;
}

.tutpage--main>hr {
    width: 96%;
}

.tutpage--image {
    width: 70%;
}