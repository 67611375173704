* {
    box-sizing: border-box;
}

.profile--container {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.profile--header {
    display: flex;

    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../../images/books-profile.jpg);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: cover;
}

.profile--header>h1 {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

.profile--content {
    padding: 30px 30px;
}

.Mui-selected {
    color: #885018 !important;
    background-color: #eee6dc !important;
    font-style: normal !important;
    border-radius: 10px !important;
}