* {
    box-sizing: border-box;
}

.effects--content {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
    background-color: whitesmoke;
}

.effects--header {
    display: flex;
    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../images/sheet-music.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.effects--header>h1 {
    color: white;
    font-weight: bold;
}

.effects--column-titles {
    display: flex;
    font-size: smaller;
    line-height: 0%;
    font-weight: 100;
}

.effects--column-titles>.composer {
    padding: 0px 0px 0px 50px;
    width: 55%;
}

.effects--column-titles>.pieces {
    padding: 0px 0px 0px 50px;
    width: 45%;
}

.effects--main {
    padding: 2em 0.5em 0.5em 2em;
    background-color: whitesmoke;
}

.effects--main>hr {
    width: 96%;
}

.effects--table-primary {
    border-spacing: 0;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    table-layout: auto;
}

.effects--th {
    border-bottom: 0.000001em solid black;
    padding: 12px 0px;
    position: relative;
    text-align: center;
    /* left: 50px; */
}


.effects--table-primary>tbody>tr {
    position: relative;
    left: 25px;
}

.effects--table-primary>tbody>tr>td {
    padding: 0.2em 2em 0.2em 0.5em;
    vertical-align: top;
    border-bottom: 0.000001em solid lightgrey;
}