* {
    box-sizing: border-box;
}

.effectDetail--container {
    position: absolute;
    right: 0px;
    width: calc(100% - 300px);
    background-color: whitesmoke;
    min-height: 100vh;
}

.effectDetail--header {
    display: flex;

    width: 100%;
    height: 110px;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 50px;

    /* Background image */
    background-image:
        linear-gradient(to right, black, transparent),
        url(../../images/music-sheet-effect-detail.jpg);
    background-position: 0% 33%;
    background-repeat: no-repeat;
    background-size: cover;
}

.effectDetail--header>h1 {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

.effectDetail--content {
    padding: 20px 50px;
}

.effectDetail--grid {
    display: grid;
    grid-gap: 20px
}

.effectDetail--pdf-container {
    grid-column: 1;
    justify-content: left;
}

.effectDetail--no-pdf {
    display: flex;
    justify-content: center;
}

.effectDetail--right-section {
    grid-column: 2;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0em 1em 0em 0em;
    font-weight: 430;
  }
  
.effectDetail--table {
    border-spacing: 0;
    width: 100%;
    border: 0.000001em solid grey;
    border-radius: 1em;
    margin-right: auto;
    margin-left: auto;
    table-layout: auto;
}

.effectDetail--table--th {
    padding: 8px 0px 8px 4px;
    position: relative;
    text-align: left;
   
}

.effectDetail--table--tr {
    border: 0.000001em solid grey;
    padding: 8px 0px 8px 4px;
    position: relative;
    text-align: left;

}

.effectDetail--table-button {
    background: none;
    cursor: pointer;
    width: 100%;
    color: rgb(34, 21, 109);
    text-align: left;
    padding: 8px 0px 8px 4px;
    border-radius: 8px;
    border: none;
}

.effectDetail--table-button:hover {
    background-color: #d3d3d3
}

.effectDetail--PreLoader {
    display:flex;
    justify-content:center;
    align-items:center;
}

.evolution--modal-modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
}

.evolution--modal-modalContent>h6 {
    font-size: 1.2em;
    padding-top: 1em;

}

.evolution--modal--table-primary {
    border-spacing: 0;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    table-layout: fixed;
    border: none
}

.evolution--th {
    padding: 12px 0px;
    position: relative;
    text-align: center;
    font-size: 16px;
    border-bottom: 0.000001em solid grey;
}

.evolution--modal--table-primary>tbody>tr {
    position: relative;
    border-bottom: 0.000001em solid lightgrey;
}

.evolution--modal--table-primary>tbody>tr>td {
    padding: 0.4em 0em 0.2em 0em;
    vertical-align: top;
}
