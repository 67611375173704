* { 
    box-sizing: border-box;
}

.search--content {
    position: absolute;
    right: 0px;
    height: 100%;
    width: calc(100% - 300px);
}

.search--header {
    display: flex;

    width: 100%;
    height: 8.3em;
    line-height: 0;
    align-items: center;
    justify-content: left;
    padding-left: 4em;

    /* Background image */
    background-image:
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), transparent),
        url(../../images/organ-stops.jpg);
    background-position: 0% 30%;
    background-repeat: no-repeat;
    background-size: cover;
}

.search--header-h1 {
    color: white;
    font-weight: bold;
}

.search--main {
    padding: 1em 1em 1em 1em;
    background-color: whitesmoke;
}

.search--sh {
    grid-area: sh;
}

.search--search{
    grid-area: searchbar;
    position: relative;
    margin-top: 2em;
    left: 20%;
}

.search--search>input {
    padding: 8px;
    font-size: 16px;
    border-color: lightgray;
    border-width: thin;
    border-style: solid;
}

.search--search-bar {
    width: 400px;
    border-radius: 7px 0px 0px 7px;
}

.search--search-button {
    border-radius: 0px 5px 5px 0px;
    border-left: none;
    cursor: pointer;
    padding: 8px;
    font-size: 16px;
    border-color: lightgray;
    border-width: thin;
    border-style: solid;
}

.search--search-button:active {
    background-color: rgb(230, 230, 230);
}

.search--results {
    grid-area: group;
    margin-top: 1em;
    margin-right: 1em;
}

.search--results-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0px 0px 20px
}

.search--results-page {
    margin-bottom: 1em;
}

.search--results-scroll {
    overflow: auto;
}

.search--results-scroll::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
  }
  
  .search--results-scroll::-webkit-scrollbar-track {
    background-color: transparent; /* Change track background color */
  }
  
  .search--results-scroll::-webkit-scrollbar-thumb {
    background-color: white; /* Change thumb color */
    border-radius: 4px; /* Adjust border radius */
  }
  
  .search--results-scroll::-webkit-scrollbar-thumb:hover {
    background-color: white; /* Change thumb color on hover */
  }

.search--results--container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    gap: 8px;
    background-color: whitesmoke;
    border-radius: 3px;
    border: 2px solid lightgrey;

}

.search--results--container:hover {
    background-color: hsl(0, 0%, 90%);
}

.search--results--title {
    grid-column: 1 / -1;
    margin-top: 1em;
    margin-left: 0.3em;
    margin-bottom: -0.3em;
    text-align: left;
    font-size: 24px;
}

.search--results--column {
    padding: 10px;
    margin-right: 10px;
    text-align: left;
}

.search--results--content {
    -webkit-columns: 3 auto;
            columns: 3 auto;
}

.item-container {
    display: block;
    justify-content: center;
    gap: 2em;
    width: auto;
}

.card {
    text-align: center;
    padding: 10px;
    height: 260px;
    border-radius: 5px;
    padding: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    grid-area: 1 / 1 / 2 / 2;
}
.card:hover {
    background-color: hsl(0, 0%, 90%);
}