* {
    box-sizing: border-box;
}

.hp--container {
    position: absolute;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 300px);
    row-gap: 20px;
    background-color: whitesmoke;
    min-height: 100vh;
}

@media all and (max-width: 980px) {
    .hp--container {
        left: 30%;
    }
}

/* Title block */

.hp--title {
    font-family: "Roboto";
    color: darkslategray;
    display: flex;
    align-items: center;
    margin-top: 5%
}

.title--2 {
    font-size: smaller;
}

.title--3 {
    color: dimgrey;
    font-size: smaller;
}

img {
    object-fit: contain;
}

/* Search bar */

.hp--search>input {
    padding: 8px;
    font-size: 16px;
    border-color: lightgray;
    border-width: thin;
    border-style: solid;
}

.hp--search-bar {
    width: 280px;
    border-radius: 5px 0px 0px 5px;
}

.hp--search-button {
    border-radius: 0px 5px 5px 0px;
    border-left: none;
    cursor: pointer;

    padding: 8px;
    font-size: 16px;
    border-color: lightgray;
    border-width: thin;
    border-style: solid;
}

.hp--search-button:active {
    background-color: rgb(230, 230, 230);
}

/* FAQ */

.hp--faq {
    margin: 1.5em 10em;
    display: flex;
    flex-direction: column;
    row-gap: none;
}

.faq--title {
    background-image: linear-gradient(whitesmoke, rgb(228, 228, 228));
    padding: 0.2em 1em;
    font-weight: 500;
    margin: 0;

    border-style: solid;
    border-color: lightgray;
    border-width: thin;
    border-radius: 5px 5px 0px 0px;
}

.faq--content {
    background-color: white;
    padding: 0.01em 1em;

    border-style: solid;
    border-color: lightgray;
    border-width: thin;
    border-radius: 0px 0px 5px 5px;
    border-top: none;
}

.faq--subtitle {
    margin-top: 1em;
    margin-bottom: 0;
}

.faq--link {
    text-decoration: none;
    color: blue;
}

.faq--link:visited {
    color: blue;
}

.faq--link:hover {
    text-decoration: underline;
    color: rgb(30, 30, 165);
}