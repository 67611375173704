* {
  box-sizing: border-box;
}

.router {
  display: grid;
  width: 100%;
  grid-template-columns: 300px calc(100% - 300px);
  grid-template-areas: "navbar routes"
}

.navbar {
  z-index: 100;
  grid-area: navbar;
}

.routes {
  grid-area: routes;
}