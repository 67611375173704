.main--container {
    width: 100%;
}

.main--buttons {
    position: relative;
    padding-left: 26px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main--submit>button {
    background-color: #13B215;
    color: whitesmoke;
    font-size: 14px;
    font-weight: 550;
    padding: 8px 16px;
    cursor: pointer;
    /* border */
    border-radius: 5px;
    border: none;
}

.main--submit>button:active {
    background-color: #109212;
}

.main--reset-clear {
    position: relative;
    right: 36px;
    display: flex;
    -webkit-column-gap: 1.2em;
            column-gap: 1.2em;
    justify-content: right;
}

.main--reset-clear>button {
    background-color: whitesmoke;
    color: black;
    font-size: 14px;
    padding: 5px 8px;
    cursor: pointer;

    border-radius: 5px;
    border-color: lightgrey;
    border-style: solid;
    border-width: thin;
}

.main--reset-clear>button:active {
    background-color: lightgrey;
}

.search--results--container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    gap: 8px;
    background-color: whitesmoke;
    border-radius: 3px;
    border: 2px solid lightgrey;

}

.search--results--container:hover {
    background-color: hsl(0, 0%, 90%);
}

.search--results--title {
    grid-column: 1 / -1;
    margin-top: 1em;
    margin-left: 0.3em;
    margin-bottom: -0.3em;
    text-align: left;
    font-size: 24px;
}

.search--results--column {
    padding: 10px;
    margin-right: 10px;
    text-align: left;
}

.item-container {
    display: block;
    justify-content: center;
    gap: 2em;
}
.card {
    text-align: center;
    padding: 10px;
    width: 100%;
    height: 260px;
    border-radius: 5px;
    padding: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    grid-area: 1 / 1 / 2 / 2;
}
.card:hover {
    background-color: hsl(0, 0%, 90%);
}
.search--results--title {
    grid-column: 1 / -1;
    margin-top: 1em;
    margin-left: 0.3em;
    margin-bottom: 0.3em;
    text-align: left;
    font-size: 24px;
}
.search--results--content {
    -webkit-columns: 3 auto;
            columns: 3 auto;
}

.search--results--column {
    padding: 10px;
    margin-right: 8px;
    text-align: left;
}
p {
    text-align: left;
}